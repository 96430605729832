<template>
  <el-button size="small" @click="AddAccount()">新增</el-button>

  <el-table border :data="AccountDatas" style="width: 100%" height="600px">
    <el-table-column type="index" width="50"> </el-table-column>
    <el-table-column label="帐号" width="180">
      <template #default="scope">
        <div style="display: flex; align-items: center">
          <el-icon>
            <timer />
          </el-icon>
          <span style="margin-left: 10px">{{ scope.row.Account }}</span>
        </div>
      </template>
    </el-table-column>

    <el-table-column label="姓名" width="180" prop="Name" />
    <el-table-column label="账号" width="180" prop="Account" />
    <el-table-column label="邮箱" width="180" prop="Email" />
    <el-table-column label="账号类型" width="180" prop="AccountTypeFormat" />

    <el-table-column label="操作">
      <template #default="scope">
        <el-button type="success" size="small" @click="AccountEdit(scope.$index, scope.row)">编辑</el-button>
        <el-button size="small" @click="AccountDelete(scope.$index, scope.row)" type="danger">删除</el-button>
        <el-button size="small" @click="RoleBind(scope.$index, scope.row)" type="primary">权限配置</el-button> 
      </template>
    </el-table-column>
  </el-table>

  <el-pagination background layout="prev, pager, next" @current-change="CurrentChange" :page-size="padding.Size"
    :total="padding.Count">
  </el-pagination>

  <!-- 修改新增用户 -->
  <el-dialog v-model="EditOrAddWindow" :title="IsAdd ? '新增' : '修改'" width="60%" :before-close="EditOrAddWindowClose">
    <div style="padding: 15px 5px;">
      <el-form ref="form" label-width="120px">
        <el-form-item label="姓名">
          <el-input v-model="AccountModel.Name"></el-input>
        </el-form-item>
        <el-form-item label="帐号">
          <el-input v-model="AccountModel.Account"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-col :span="11">
            <el-input v-model="AccountModel.Password"></el-input>
          </el-col>
          <el-col :span="2">
          </el-col>
          <el-col :span="11">
            不修改请留空
          </el-col>

        </el-form-item>
        <el-form-item label="手机">
          <el-input v-model="AccountModel.Phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="AccountModel.Email"></el-input>
        </el-form-item>
        <el-form-item label="账号角色"  >
        <el-select v-model="AccountModel.Roles"  placeholder='请选择账号角色'>
          <el-option label="员工" value="StoreUser">员工</el-option>
          <el-option label="管理员"   value="StoreAdmin">管理员</el-option>
        </el-select>
      </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="EditOrAddWindowClose">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 绑定权限 -->
  <el-dialog v-model="RoleBindWindow" title="权限绑定" width="60%" :before-close="RoleBindWindowClose">
    <br />
    <el-scrollbar height="400px">
      <el-tag v-for="(item, index) in AccountModel.Roles" :key="index" class="ml-2" closable type="success">{{
        item.Name
      }}</el-tag>
      <el-divider></el-divider>
      <!-- RoleDatas -->
      <el-table :data="RoleDatas" style="width: 100%">
        <el-table-column label="Name" prop="Name" />
        <el-table-column label="Detail" prop="Detail" />
        <el-table-column align="right">
          <template #default="scope">
            <el-button size="small" @click="RoleBindSave(scope.$index, scope.row)">绑定</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="RoleBindWindowClose">关闭</el-button>
      </span>
    </template>
  </el-dialog>
 
  <div class="content"></div>
</template>
<script >
import axios from "../../../commons/AxiosMethod.js"
import tools from "../../../commons/tools.js"
import { ElMessageBox, ElMessage } from "element-plus"

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      AccountDatas: [],
      AccountModel: {}, // 选中用户
      EditOrAddWindow: false, // 修改或者新增窗口
      IsAdd: false, // 是否是新增

      RoleBindWindow: false,
      RoleDatas: [],

      NewPassword: "", 

      padding: {
        Page: 1,
        Size: 30,
        Count: 0
      }
    }
  },
  name: "App",
  mounted() {
    this.GetAccount()
    this.GetRoleDatas()
  },
  components: {},
  methods: {
    // 生成签名
    CreateSign(index, item) {
      tools.confirm("重新生成后之前信息将失效", () => {
        axios.apiMethod(
          "/Admins/Account/CreateSign",
          "post",
          item,
          (result) => {
            if (result.Code == 200) {
              ElMessage({
                message: "生成成功",
                type: "success"
              })
              this.GetAccount()
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error"
              })
            }
          }
        )
      })
    },
 
 
    // 获取权限数据
    GetRoleDatas() {
      var param = { Where: {} }
      axios.apiMethod("/api/Role/GetList", "post", param, (result) => {
        if (result.Code == 200) {
          this.RoleDatas = result.Data.Datas
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 绑定权限数据窗口
    RoleBindWindowClose() {
      this.RoleBindWindow = false
    },
    // 保存绑定权限
    RoleBindSave(index, item) {
      const param = { AccountId: this.AccountModel.Id, RoleId: item.Id }
      axios.apiMethod("/Admins/Account/RoleBind", "post", param, (result) => {
        if (result.Code == 200) {
          this.EditOrAddWindowClose()
          this.GetAccount()
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 权限配置窗口
    RoleBind(index, item) {
      this.AccountModel = item
      this.RoleBindWindow = true
    },
    EditOrAddWindowClose() {
      this.EditOrAddWindow = false
    },
    // 保存
    EditOrAddSave() {

      const param = this.AccountModel
      axios.apiMethod(
        "/Admins/Account/AddOrEditAccount",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.EditOrAddWindowClose()
            this.GetAccount()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 创建用户
    AddAccount() {
      this.AccountModel = {
        Email: "",
        Name: "",
        Phone: "",
        Password: "",
        Roles: []
      }
      this.IsAdd = true
      this.EditOrAddWindow = true
    },
    // 编辑
    AccountEdit(index, row) {
      this.EditOrAddWindow = true
   
      this.AccountModel = row
      this.IsAdd = false
      this.AccountModel.Password = "";
    },

    // 删除
    AccountDelete(index, row) {
      ElMessageBox.confirm("确定要删除吗？")
        .then(() => {
          axios.apiMethod(
            "/Admins/Account/DeleteAccounts?Id=" + row.Id,
            "get",
            {},
            (result) => {
              if (result.Data.Success ) {
                ElMessageBox({ 
                 title:  "提示",
                  message: "删除成功",
                  type: "success"
                })
                this.GetAccount()
              }else{
                ElMessageBox({
                  title:" 错误！",
                  type:"error",
                  message: result.Data.Msg, 
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    CurrentChange(index) {
      this.padding.Page = index
      this.GetAccount()
    },
    // 获取用户列表
    GetAccount() {

      this.padding.Where = {
        Name: "",
        Account: ""
      }

      axios.apiMethod(
        "/Admins/Account/GetAccounts",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.AccountDatas = result.Data.Datas
            this.padding.Page = result.Data.Page
            this.padding.Size = result.Data.Size
            this.padding.Count = result.Data.Count
          }
        }
      )
    }
  }
}
</script>

<style scoped>

</style>
